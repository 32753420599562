<script lang="ts" module>
  import { tv, type VariantProps } from "tailwind-variants";
  export const sheetVariants = tv({
    base: "bg-background data-[state=open]:animate-in data-[state=closed]:animate-out fixed z-50 gap-4 p-6 shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
    variants: {
      side: {
        top: "data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top inset-x-0 top-0 border-b",
        bottom:
          "data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom inset-x-0 bottom-0 border-t",
        left: "data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left inset-y-0 left-0 h-full w-3/4 border-r sm:max-w-sm",
        right:
          "data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right inset-y-0 right-0 h-full w-3/4 border-l sm:max-w-sm"
      }
    },
    defaultVariants: {
      side: "right"
    }
  });

  export type Side = VariantProps<typeof sheetVariants>["side"];
</script>

<script lang="ts">
  import {
    Dialog as SheetPrimitive,
    type WithoutChildrenOrChild
  } from "bits-ui";
  import type { Snippet } from "svelte";
  import SheetOverlay from "./sheet-overlay.svelte";
  import { cn } from "@/helpers/tw-utils.js";
  import X from "lucide-svelte/icons/x";

  let {
    ref = $bindable(null),
    class: className,
    side = "right",
    portalProps,
    children,
    ...restProps
  }: WithoutChildrenOrChild<SheetPrimitive.ContentProps> & {
    portalProps?: SheetPrimitive.PortalProps;
    side?: Side;
    children: Snippet;
  } = $props();
</script>

<SheetPrimitive.Portal {...portalProps}>
  <SheetOverlay />
  <SheetPrimitive.Content
    bind:ref
    class={cn(sheetVariants({ side }), className)}
    {...restProps}>
    {@render children?.()}
    <SheetPrimitive.Close
    class="absolute ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none data-[state=open]:text-muted-foreground top-[22px] right-[24px] opacity-1 w-[32px] h-[32px] flex justify-center items-center text-medium-gray hover:text-white rounded-[8px] bg-blue-gray border border-dark-gray">
    <X class="size-4" />
      <span class="sr-only">Close</span>
    </SheetPrimitive.Close>
  </SheetPrimitive.Content>
</SheetPrimitive.Portal>
