<script lang="ts">
  import FieldShow from "@/components/ui/dynamic-fields/field-show.svelte";
  import LoginDialog from "@/components/login/login-dialog.svelte";
  import Badge from "@/components/ui/badge.svelte";
  import { Button } from "@/components/ui/button";
  import { Image } from "@/components/ui/image";
  import { Input } from "@/components/ui/input";
  import QrCode from "@/components/ui/qrcode.svelte";
  import Typography from "@/components/ui/typography/typography.svelte";
  import { gameDetails, games, getBoostingTextDescription } from "@/games/all";
  import { getProductDetails } from "@/games/lol/account_info";
  import { hasOwnProperty } from "@/helpers/has-own-property";
  import { typedEntries } from "@/helpers/typed-entries";
  import { client, getTRPCErrors } from "@/helpers/trpc/trpc-client";
  import { portal } from "@/helpers/usePortal";
  import Bitcoin from "@/images/bitcoin.svg";
  import RemoveIcon from "@/images/delete-outlined.svg";
  import Played10 from "@/images/honor-level.svg";
  import CartIcon from "@/images/local_mall.svg";
  import EmailIcon from "@/images/mail.svg";
  import RadioChecked from "@/images/radio-checked.svg";
  import RadioUnchecked from "@/images/radio-unchecked.svg";
  import Rocket from "@/images/rocket_launch.svg";
  import Handmade from "@/images/sign_language.svg";
  import SkinIncluded from "@/images/skin.svg";
  import Stripe from "@/images/stripe.svg";
  import type {
    CreateOrderRequest,
    OrderLineItemsType
  } from "@/server/types/order-details";
  import {
    cartItems,
    isCartOpen,
    removeCartItem,
    type BoostingCartItem
  } from "@/stores/cart";
  import { discountCode, discountPercentage } from "@/stores/discounts";
  import { user } from "@/stores/user";
  import { loadStripe } from "@stripe/stripe-js";
  import * as Alert from "@/components/ui/alert";
  import * as Sheet from "@/components/ui/sheet";
  import * as Dialog from "@/components/ui/dialog";
  import { sineIn } from "svelte/easing";
  import { fly } from "svelte/transition";
  import LoadingOutlined from "lucide-svelte/icons/loader-circle";
  import Repeat from "lucide-svelte/icons/repeat";
  import StripePaymentMethods from "./stripe-payment-methods.svelte";
  import i18n from "@/stores/i18n";

  interface Props {
    stripe_public_key: string;
  }

  const t = (key: string, options?: any) =>
    $i18n.t(key, { ns: "common", ...options }) as unknown as string;

  let { stripe_public_key }: Props = $props();

  let transitionParams = {
    x: 320,
    duration: 200,
    easing: sineIn
  };
  let total = $derived(
    Object.values($cartItems).reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    )
  );

  let email = $state("");
  let isLoadingCheckout = $state(false);
  let checkoutErrors: string[] = $state([]);

  let btcDialogOpen = $state(false);
  let btcAmount = $state(0);
  let btcAddress = $state("");
  let btc_timeinterval: ReturnType<typeof setTimeout> | null = null;
  let btcQrcode: string = $state("");

  let selectedPaymentMethod: "stripe" | "bitcoin" = $state("stripe");

  let showLoginDialog: boolean = $state(false);

  const startCheckout = async (e: Event) => {
    e.preventDefault();
    const line_items: OrderLineItemsType = Object.values($cartItems);

    // check for auth for buying boosting or any subscription item
    if (
      line_items.some(
        (item) => item.type === "boosting" || item.subscription
      ) &&
      !$user
    ) {
      showLoginDialog = true;
      return;
    }

    isLoadingCheckout = true;

    try {
      if ($user) email = $user.email;

      const orderRequest: CreateOrderRequest = {
        email,
        payment_method: selectedPaymentMethod,
        discount: $discountCode,
        line_items
      };

      const checkoutSession =
        await client.payment.createOrder.mutate(orderRequest);

      if (checkoutSession.payment_method == "stripe") {
        const stripe = await loadStripe(stripe_public_key);

        if (!stripe) {
          isLoadingCheckout = false;
          checkoutErrors = [t("cart.errors.stripe_init")];
          return;
        }

        if (!checkoutSession.id) {
          checkoutErrors = [t("cart.errors.create_order")];
          return;
        }

        //remove items from cart
        Object.entries($cartItems).forEach(([id, item]) => removeCartItem(id));

        const result = await stripe.redirectToCheckout({
          sessionId: checkoutSession.id
        });

        if (result.error) {
          checkoutErrors = [t("cart.errors.stripe_redirect")];
        }
      } else if (checkoutSession.payment_method == "bitcoin") {
        //close cart
        $isCartOpen = false;

        if (!checkoutSession.total_in_btc || !checkoutSession.address) {
          checkoutErrors = [t("cart.errors.create_order")];
          return;
        }

        btcAmount = checkoutSession.total_in_btc;
        btcAddress = checkoutSession.address;
        const order_id = checkoutSession.order_id;

        btcQrcode = `bitcoin:${btcAddress}?amount=${btcAmount}`;

        //open dialog
        btcDialogOpen = true;

        if (btc_timeinterval) clearTimeout(btc_timeinterval);

        btc_timeinterval = setInterval(async () => {
          const intent_status =
            await client.payment.orderPaymentStatus.query(order_id);

          if (intent_status.payment_status === "paid") {
            window.location.href = `/payment/success/${order_id}`;
          }
        }, 5000);
      }
    } catch (error) {
      checkoutErrors = getTRPCErrors(error);
    }

    isLoadingCheckout = false;
  };

  const getAddonValue = (
    cartItem: BoostingCartItem,
    addonName: string
  ): boolean | Record<string, any> | undefined => {
    if (hasOwnProperty(cartItem.data.addons, addonName))
      //@ts-ignore
      return cartItem.data.addons[addonName];

    return false;
  };
</script>

<Sheet.Root bind:open={$isCartOpen}>
  <Sheet.Trigger>
    <span use:portal={"cart-button"} class="hidden">
      <Button
        variant="secondary"
        class="relative p-[10px]"
        onclick={() => ($isCartOpen = !$isCartOpen)}>
        {#if Object.values($cartItems).length > 0}
          <span
            class="absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-destructive">
            <Typography variant="small">
              {Object.values($cartItems).length}</Typography>
          </span>
        {/if}

        <Image src={CartIcon} alt="cart" class="h-5 w-5" />
      </Button>
    </span>
  </Sheet.Trigger>
  <Sheet.Content class="w-[350px] overflow-x-hidden sm:max-w-md">
    <div class="mb-6 flex items-center justify-between">
      <Typography variant="lead">
        Cart <span class="text-medium-gray"
          >({Object.keys($cartItems).length})</span>
      </Typography>
    </div>
    <div class="-ml-6 mb-4 min-h-[1px] w-[calc(100%+48px)] bg-dark-gray"></div>

    <div class="flex flex-1 flex-col gap-2">
      {#if Object.values($cartItems).length}
        <aside class="flex flex-1 flex-col gap-2">
          {#each Object.entries($cartItems) as [id, cartItem] (id)}
            <div
              transition:fly={transitionParams}
              class="grid gap-4 rounded-xl border border-dark-gray bg-theme-black p-4">
              <div class="flex flex-row items-center justify-between">
                <div class="flex items-center gap-2">
                  <div
                    class="flex h-12 w-12 items-center justify-center rounded-[8px] border border-dark-gray bg-blue-gray">
                    {#if cartItem.type === "account"}
                      <img
                        src={gameDetails["League of Legends"].icon}
                        alt="Product"
                        class="h-6" />
                    {:else}
                      <img
                        src={gameDetails[cartItem.data.game].icon}
                        alt="Product"
                        class="h-6" />
                    {/if}
                  </div>
                  <div class="flex flex-col">
                    <div class="flex gap-2">
                      {#if cartItem.type === "account"}
                        {@const details = getProductDetails(
                          cartItem.data.productId
                        )}
                        {details?.region.toUpperCase()} Level {details?.level}
                      {:else}
                        {getBoostingTextDescription(cartItem.data)}
                      {/if}
                    </div>
                    <div class="text-muted">
                      {#if cartItem.type === "account"}
                        {t("cart.smurf_account")}
                      {:else}
                        {t(`games:boosting.types.${cartItem.data.type}.title`)}
                      {/if}
                    </div>
                  </div>
                </div>

                <div class="flex items-center gap-2">
                  <div class="flex items-center p-2 text-base font-semibold">
                    {cartItem.currency}{cartItem.price.toFixed(2)}
                    {#if cartItem.subscription}<span
                        class="text-xs text-neutral-500"
                        >{t("cart.monthly")}</span>
                    {/if}
                  </div>
                  <button
                    disabled={isLoadingCheckout}
                    class=" inline-flex min-w-4 items-center"
                    onclick={() => removeCartItem(id)}
                    ><Image class="h-6 w-6" src={RemoveIcon} /></button>
                </div>
              </div>

              <div class="flex w-full flex-row flex-wrap justify-start gap-2">
                {#if cartItem.type === "account"}
                  {@const details = getProductDetails(cartItem.data.productId)}
                  <Badge image={Rocket} imageAlt={"Instant Delivery"}>
                    {t("cart.instant_delivery")}
                  </Badge>
                  {#if details?.accountType == "hand"}
                    <Badge image={Handmade} imageAlt={"Handmade"}
                      >{t("cart.handmade")}</Badge>
                  {/if}
                  {#if details?.mmrType === "played"}
                    <Badge image={Played10} imageAlt={"Handmade"}
                      >{t("cart.played10")}</Badge>
                  {/if}
                  {#if cartItem.subscription}
                    <Badge>
                      <Repeat class="mr-2 h-6 w-6" />
                      {t("cart.subscription_discount")}
                    </Badge>
                  {/if}
                {/if}
                {#if $discountPercentage}
                  <Badge>
                    {t("cart.discount", {
                      percentage: $discountPercentage * 100
                    })}
                  </Badge>
                {/if}
                {#if cartItem.price == 0}
                  <Badge
                    class=" flex h-12 w-fit items-center justify-center rounded-[8px] border border-dark-gray bg-blue-gray px-3 py-2"
                    >{t("cart.free")}</Badge>
                {/if}
                {#if cartItem.type === "account" && cartItem.data.selected_skin}
                  <Badge image={SkinIncluded} imageAlt={"Skin Included"}>
                    {t("cart.skin")}: {cartItem.data.selected_skin}
                  </Badge>
                {/if}

                {#if cartItem.type === "boosting"}
                  {@const selectedTypeDetails =
                    games?.[cartItem.data.game]?.[cartItem.data.type]}
                  {#each Object.entries(selectedTypeDetails?.fields || {}) as [fieldName, field]}
                    {#if hasOwnProperty(cartItem.data, fieldName)}
                      <div class="flex w-full flex-wrap items-center gap-4">
                        <Typography color="muted" variant="small"
                          >{t(
                            `games:boosting.fields.${fieldName}.title`
                          )}:</Typography>
                        <Typography
                          class="flex items-center gap-2"
                          variant="small"
                          color="light_gray">
                          <FieldShow {field} value={cartItem.data[fieldName]} />
                        </Typography>
                      </div>
                    {/if}
                  {/each}

                  {#each typedEntries(selectedTypeDetails?.addons || {}) as [addonName, addon]}
                    {#if getAddonValue(cartItem, addonName)}
                      <Badge
                        >{t(`games:boosting.addons.${addonName}.title`)}</Badge>
                    {/if}
                  {/each}
                {/if}
              </div>
            </div>
          {/each}
        </aside>

        <div
          class="-ml-6 mt-4 w-[calc(100%+48px)] border-t border-dark-gray bg-theme-black p-4">
          <div class="flex items-center justify-between pb-4">
            <Typography color="muted" class="font-normal"
              >{t("cart.total")}:</Typography>
            <Typography variant="h4" as="p">
              {Object.values($cartItems)[0]?.currency}{total.toFixed(
                2
              )}</Typography>
          </div>

          <div class="-ml-6 mb-4 min-h-[1px] w-[calc(100%+48px)] bg-dark-gray">
          </div>

          <div class="relative pb-4 text-white">
            <form onsubmit={startCheckout} class="w-full">
              {#if $user}
                <div class="mt-2 rounded-xl border border-dark-gray">
                  <Image
                    src={EmailIcon}
                    class="ml-[16px]  inline-block h-6 w-6" />
                  <Input
                    id="email_input"
                    disabled={true}
                    value={$user.email}
                    class="-ml-1 inline-block w-[80%] border-none px-[12px] py-6 font-poppins text-[16px] font-[500] leading-[150%] placeholder:!font-normal placeholder:text-medium-gray focus-visible:ring-0 focus-visible:ring-offset-0"
                    placeholder="Enter your email">
                  </Input>
                </div>
              {:else}
                <div class="mt-2 rounded-xl border border-dark-gray">
                  <Image
                    src={EmailIcon}
                    class="ml-[16px]  inline-block h-6 w-6" />
                  <Input
                    id="email_input"
                    disabled={isLoadingCheckout}
                    bind:value={email}
                    class="-ml-1 inline-block w-[80%] border-none px-[12px] py-6 font-poppins text-[16px] font-[500] leading-[150%] placeholder:!font-normal placeholder:text-medium-gray focus-visible:ring-0 focus-visible:ring-offset-0"
                    placeholder="Enter your email">
                  </Input>
                </div>
              {/if}
            </form>
          </div>

          <div class="-ml-6 mb-4 min-h-[1px] w-[calc(100%+48px)] bg-dark-gray">
          </div>

          <Typography class="pb-2"
            >{t("cart.payment_methods.title")}</Typography>
          {#if checkoutErrors.length > 0}
            <Alert.Root variant="destructive" class="my-2">
              <Alert.Description>
                <span class="font-medium"
                  >{t("cart.errors.checkout_errors")}</span>
                <ul class="ml-8 mt-0 list-inside list-disc">
                  {#each checkoutErrors as error}
                    <li>{error}</li>
                  {/each}
                </ul>
              </Alert.Description>
            </Alert.Root>
          {/if}
          <div class="grid w-full gap-2">
            {#if !isLoadingCheckout}
              <div class="mb-2 flex flex-col gap-2">
                <div>
                  <input
                    name="paymentMethod"
                    type="radio"
                    id="stripe"
                    value="stripe"
                    class="peer hidden"
                    bind:group={selectedPaymentMethod} />
                  <label
                    for="stripe"
                    class="flex cursor-pointer gap-2 rounded-2xl border-2 border-dark-gray p-3 peer-checked:border-accent peer-checked:bg-blue-gray"
                    ><Image src={Stripe} alt="Stripe" class="h-6 w-6" />
                    {t("cart.payment_methods.stripe")}
                    <StripePaymentMethods />
                    <Image
                      src={selectedPaymentMethod === "stripe"
                        ? RadioChecked
                        : RadioUnchecked}
                      alt=""
                      class="ml-auto h-6 w-6" />
                  </label>
                </div>
                <div>
                  <input
                    name="paymentMethod"
                    type="radio"
                    id="bitcoin"
                    value="bitcoin"
                    class="peer hidden"
                    bind:group={selectedPaymentMethod} />
                  <label
                    for="bitcoin"
                    class="flex cursor-pointer gap-2 rounded-2xl border-2 border-dark-gray p-3 peer-checked:border-accent peer-checked:bg-blue-gray">
                    <Image src={Bitcoin} alt="Bitcoin" class="h-6 w-6" />
                    {t("cart.payment_methods.bitcoin")}
                    <Image
                      src={selectedPaymentMethod === "bitcoin"
                        ? RadioChecked
                        : RadioUnchecked}
                      alt=""
                      class="ml-auto h-6 w-6" />
                  </label>
                </div>
              </div>
              <div
                class="-ml-6 mb-4 min-h-[1px] w-[calc(100%+48px)] bg-dark-gray">
              </div>
              <Button
                variant="accent"
                onclick={startCheckout}
                class="w-full rounded-xl">
                {t("cart.checkout_button")}
              </Button>
            {:else}
              <LoadingOutlined class="m-auto inline h-8 w-8 animate-spin" />
            {/if}
          </div>
        </div>
      {:else}
        <p>{t("cart.empty")}</p>
      {/if}
    </div>
  </Sheet.Content>
</Sheet.Root>

<Dialog.Root bind:open={btcDialogOpen}>
  <Dialog.Content
    interactOutsideBehavior="ignore"
    escapeKeydownBehavior="ignore">
    <Dialog.Header>
      <Dialog.Title>{t("cart.bitcoin.title")}</Dialog.Title>
    </Dialog.Header>
    <div class="break-words">
      {t("cart.bitcoin.amount", { amount: btcAmount, address: btcAddress })}
      <br />
    </div>
    <div class="flex justify-center py-4">
      <QrCode value={btcQrcode} />
    </div>
    {t("cart.bitcoin.or")}
    <a class="font-bold" href={`bitcoin:${btcAddress}?amount=${btcAmount}`}>
      {t("cart.bitcoin.offline")}
    </a>
    <br />
    <b class="text-red-500">
      {t("cart.bitcoin.note")}
    </b>
    <br />
    <div class="flex w-full items-center">
      <LoadingOutlined class="m-auto inline h-8 w-8 animate-spin text-center" />
    </div>
  </Dialog.Content>
</Dialog.Root>
<LoginDialog
  bind:open={showLoginDialog}
  bind:email
  onOpenChange={() => (showLoginDialog = !showLoginDialog)} />
